.welcome-container {
  background-color: #ffffff !important;
  color: #000 !important;
}

.login-body-gtcdoc {
  background-image: url("/sky.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #ffffff !important;
  padding: 10px;
  min-height: calc(100vh);
}

.login-body {
  background-image: url("/sky.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #ffffff !important;
  padding: 10px;
  min-height: calc(100vh);
}

.about-body {
  background-color: #ffffff !important;
  padding: 20px;
}

.contact-body {
  background-color: #f9fafa !important;
  padding: 20px;
}

.login-container {
  position: relative !important;
  margin-top: 25vh !important;
  max-width: 100%;
  border-radius: 0.7rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

@media (max-width: 600px) {
  .login-container {
    position: relative !important;
    margin-top: 8vh !important;
    max-width: 100%;
    border-radius: 0.7rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
}

.about-container {
  position: relative !important;
  margin-top: 6vh !important;
  max-width: 100%;
  border-radius: 0.7rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.contact-container {
  position: relative !important;
  margin-top: 6vh !important;
  max-width: 100%;
  border-radius: 0.7rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
}

.login-field {
  height: 3rem;
  position: relative;
  border-radius: 2rem;
  padding-left: 50px;
}

.submitbutton {
  background-color: #00adf0;
  height: 3rem;
  position: relative;
  border-radius: 2rem;
  padding-left: 50px;
}

@media (min-width: 768px) {
  .navbar-expand-md {
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.sign-in-box {
  border-right: 1px solid #007bff;
}

/* for contact send */
.login-box form #button_animated {
  position: relative;
  display: inline-block;
  font-size: 16px;
  text-decoration: none;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 15px;
  border-color: #fff;
}

.login-box form #button_animated2 {
  position: relative;
  display: inline-block;
  font-size: 16px;
  text-decoration: none;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 15px;
  border-color: #fff;
}

.login-box #button_animated:hover {
  background: #28a745;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #28a745, 0 0 10px #28a745, 0 0 15px #28a745,
    0 0 20px #28a745;
}

.login-box #button_animated2:hover {
  background: #dc3545;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #dc3545, 0 0 10px #dc3545, 0 0 15px #dc3545,
    0 0 20px #dc3545;
}

.login {
  opacity: 1;
  padding: 30px;
}

.login-dotted {
  opacity: 1;
  padding: 30px;
  padding-bottom: 70px;
  padding-top: 70px;
}

.main-footer {
  margin: center;
  text-align: center;
}

.footer-bottom {
  background-color: #f9fafa;
}

.icontext {
  padding: 10px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

/* logout css */
#logoutDialog .modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: transparent !important;
  background-clip: padding-box;
  border: none !important;
  border-radius: 0.3rem;
  outline: 0;
}

.custom-btn {
  background-color: #00adf0;
  border-color: #00adf0;
}

.custom-btn:hover {
  background-color: #00adf0;
  border-color: #00adf0;
}
